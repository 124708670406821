import React from "react";

const CustomAnchor = ({ text, href, onClick }) => {
  return (
    <a
      href={href}
      onClick={onClick}
      style={{
        textDecoration: "none",
        color: "#FFFFFF",
        fontWeight: 600,
      }}
    >
      {text}
    </a>
  );
};

const Footer = ({
  aboutRef,
  productsRef,
  galleryRef,
  benefitsRef,
  contactsRef,
  scrollIntoView,
}) => {
  return (
    <div
      className="w-100 text-center"
      style={{
        backgroundColor: "#00a1c9",
        paddingTop: 60,
        paddingBottom: 60,
      }}
    >
      <div
        className="row footer-menu gy-3"
        style={{ marginLeft: "auto", marginRight: "auto" }}
      >
        <div className="col">
          <CustomAnchor
            text={"About"}
            href="#/"
            onClick={() => scrollIntoView(aboutRef)}
          />
        </div>
        <div className="col">
          <CustomAnchor
            text={"Products"}
            href="#/"
            onClick={() => scrollIntoView(productsRef)}
          />
        </div>
        <div className="col">
          <CustomAnchor
            text={"Gallery"}
            href="#/"
            onClick={() => scrollIntoView(galleryRef)}
          />
        </div>
        <div className="col">
          <CustomAnchor
            text={"Benefits"}
            href="#/"
            onClick={() => scrollIntoView(benefitsRef)}
          />
        </div>
        <div className="col">
          <CustomAnchor
            text={"Contacts"}
            href="#/"
            onClick={() => scrollIntoView(contactsRef)}
          />
        </div>
      </div>
      <div
        className="row mt-5"
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          width: 300,
          fontSize: 14,
          opacity: 0.7,
          color: "#FFFFFF",
        }}
      >
        <div>
          <span>© All Rights Reserved. BarX</span>
        </div>
        <div>
          <span>admin@barx.ae</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
